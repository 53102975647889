.total-container label {
  width: 120px;
  text-align: right;
  display: inline-block;
}
.el-table__body .warning-row {
  background: oldlace !important;
}
.el-table__body .success-row {
  background: #f0f9eb;
}
